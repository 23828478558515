import { FileDown, FileSearch, PersonStanding, Quote, ShieldCheck, Sparkle } from 'lucide-react'
import { Badge } from '../components-landing/ui/badge'
import FAQ from '../components-landing/faq'
import TryFree from '../components-landing/commons/try-free'
import { useEffect } from 'react'
import Footer from '../Components/Footer'
import WriterFormUI from '../assets/images/writerformui.png'
import GetStartedButton from '../components-landing/get-started'
import { usePostHog } from 'posthog-js/react'

const ProductsPage = () => {
	const posthog = usePostHog()

	useEffect(() => {
		posthog.capture('page-viewed', { page: 'writer' })
		window.scrollTo(0, 0)
	}, [])

	return (
		<>
			<div className="mt-36 mx-auto flex flex-col gap-10 px-1 md:px-0">
				<div className="md:max-w-2xl flex flex-col items-center mx-auto gap-2">
					<div className="flex gap-2 items-center text-success-default bg-success-default bg-opacity-20 p-2 rounded-2xl border-b-4 border-success-default">
						<ShieldCheck size={50} />
						<h1 className="md:text-6xl text-4xl font-bold">Undetectable Essays</h1>
					</div>
					<h1 className="md:text-6xl text-5xl font-bold">created in seconds</h1>
					<div className="text-gray-700 text-center my-4 text-lg">
						Generate an essay, research paper, article, or any other type of content within seconds, with in-text
						citations from real articles, journals, and websites.
					</div>
					<GetStartedButton redirectUrl="/choose" />
				</div>
				<div className="max-w-6xl w-full mx-auto mb-10">
					<img src={WriterFormUI} className="rounded-xl overflow-hidden max-h-96 object-cover object-top w-full" />
				</div>
				<div className="bg-gray-100 w-full py-24">
					<div className="max-w-6xl w-full mx-auto rounded-lg">
						<div className="flex flex-col items-center gap-5">
							<Badge size="lg" className="gap-2 bg-secondary bg-opacity-20 font-bold uppercase text-secondary">
								<Sparkle /> TOOLS
							</Badge>
							<div className="flex flex-col gap-6 max-w-4xl mx-auto">
								<h2 className="text-center md:text-6xl text-5xl">Empower your writing with AI</h2>
								<div className="text-gray-700 text-center text-lg">
									Create an essay, research paper, article, or any other type of content within seconds, with in-text
									citations from real articles, journals, and websites.
								</div>
							</div>
							<div className="grid grid-cols-1 md:grid-cols-3 gap-10 mt-10 mb-4">
								<div className="flex flex-col gap-4 items-center shadow-lg bg-white border px-3 py-6 rounded-xl border-gray-300">
									<div className="h-14 w-14 bg-gray-300 rounded-full flex items-center justify-center text-secondary">
										<Quote size={24} />
									</div>
									<h3 className="text-xl text-center">Real Citations</h3>
									<p className="text-lg text-gray-700 text-center">
										Get real, live citations pulled from internet sources to use in your content.
									</p>
								</div>
								<div className="flex flex-col gap-4 items-center shadow-lg bg-white border px-3 py-6 rounded-xl border-gray-300">
									<div className="flex justify-center gap-2">
										<div className="h-14 w-14 bg-gray-300 rounded-full flex items-center justify-center text-secondary">
											<FileSearch size={24} />
										</div>
										<div className="h-14 w-14 bg-gray-300 rounded-full flex items-center justify-center text-secondary">
											<PersonStanding size={24} />
										</div>
									</div>
									<h3 className="text-xl text-center">AI Humanizer & Detector</h3>
									<p className="text-lg text-gray-700 text-center">
										Bypass AI detectors from leading tools like TurnItIn with our AI humanizer.
									</p>
								</div>
								<div className="flex flex-col gap-4 items-center shadow-lg bg-white border px-3 py-6 rounded-xl border-gray-300">
									<div className="flex justify-center gap-2">
										<div className="h-14 w-14 bg-gray-300 rounded-full flex items-center justify-center text-secondary">
											<FileDown size={24} />
										</div>
									</div>
									<h3 className="text-xl text-center">Document Export</h3>
									<p className="text-lg text-gray-700 text-center">
										Your content comes pre-formatted in the editor—just export and submit!
									</p>
								</div>
							</div>
							<GetStartedButton redirectUrl="/choose" />
						</div>
					</div>
				</div>
				<div className="pt-8">
					<div className="max-w-6xl w-full mx-auto rounded-3xl bg-secondary py-20 px-10 shadow-xl shadow-secondary">
						<div className="text-white md:text-7xl text-5xl text-center max-w-4xl mx-auto font-bold">
							How does our essay writer work?
						</div>
						<div className="grid grid-cols-1 md:grid-cols-3 gap-10 mt-14">
							{[
								'Select your desired writing style and tone for your content, as well as citation formats if necessary.',
								'Input your topic and choose from AI-generated subtitles to craft your essay outline. ',
								'Clarify will effortlessly create  AI-undetectable content using real citations to guarantee accuracy.',
							].map((text, index) => (
								<div key={index} className="flex flex-col gap-4 items-center group">
									<div className="h-14 w-14 rounded-full flex items-center justify-center border-gray-300 border-2 text-white text-2xl font-bold group-hover:bg-white group-hover:text-secondary group-hover:border-white group-hover:scale-125 relative group-hover:bottom-1 transition-all">
										{index + 1}
									</div>
									<div className="text-xl text-center text-gray-300 group-hover:text-white font-semibold">{text}</div>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className="max-w-6xl w-full mx-auto flex flex-col gap-12 pb-24">
					<FAQ />
					<TryFree />
				</div>
			</div>
			<Footer />
		</>
	)
}

export default ProductsPage
