import { BriefcaseBusiness, FileCheck2, GraduationCap, LibraryBig, Newspaper, WandSparkles } from 'lucide-react'
import { Button } from '../components-landing/ui/button'
import { Link } from 'react-router-dom'
import { Badge } from '../components-landing/ui/badge'
import FAQ from '../components-landing/faq'
import TryFree from '../components-landing/commons/try-free'
import { useEffect } from 'react'
import Footer from '../Components/Footer'
import { DetectorEditor } from './Humanizer/editor'
import { useInView } from 'react-intersection-observer'
import { usePostHog } from 'posthog-js/react'

const DetectorLandingPage = () => {
	const posthog = usePostHog()
	const { ref, inView } = useInView({
		/* Optional options */
		threshold: 0,
	})
	useEffect(() => {
		posthog.capture('page-viewed', { page: 'detector' })
		window.scrollTo(0, 0)
	}, [])

	return (
		<>
			<div className="pt-28 mx-auto flex flex-col gap-10 px-1 md:px-0 bg-gray-50">
				<div className="max-w-6xl w-full mx-auto flex flex-col gap-12 pb-24">
					<div className="flex flex-col items-center gap-2 max-w-5xl w-full mx-auto">
						<div className="text-gray-600 uppercase">AI Detector</div>
						<div className="md:text-6xl text-4xl font-semibold text-center">
							Our AI detector that's both <span className="text-secondary">99% accurate</span> and completely
							<span className="text-secondary"> free.</span>
						</div>
						<div className="text-gray-700 text-center max-w-3xl my-4">
							Our AI content detector ranks among the most accurate in the industry. Use it to determine if your content
							passes AI checks from leading detection tools, including Turnitin.
						</div>
						<div
							className={`transform transition-all duration-700 w-full ${
								inView ? 'opacity-100 scale-100' : 'opacity-0 scale-90'
							}`}
							ref={ref}
						>
							<DetectorEditor />
						</div>
					</div>
					<div className="bg-slate-900 py-24 rounded-3xl relative overflow-hidden">
						<div className="flex flex-col items-center gap-8 max-w-3xl mx-auto">
							<div className="text-4xl font-semibold text-center text-white z-10">
								<span className="text-secondary">Looking to bypass AI detectors? </span> Humanize your content with
								Clarify!
							</div>
							<div className="text-white text-center max-w-lg z-10">
								Our AI Humanizer creates human-sounding content and avoids many of the common AI phrases and words that
								can make content appear AI-generated. Use our humanizer to avoid detection from Turnitin, Originality,
								and other popular AI detectors on the market.
							</div>
							<Link to="/ai-humanizer" className="z-10">
								<Button variant="outline" className="bg-white text-xl py-6">
									<GraduationCap className="mr-2" />
									Humanize Now
								</Button>
							</Link>
						</div>
						<div className="flex flex-col items-center">
							<div
								className="w-full h-[120%] absolute opacity-50 bottom-[-300px]"
								style={{ background: 'radial-gradient(circle at center, #3b82f6 0%, rgb(15 23 42) 50%)' }}
							/>
						</div>
					</div>
					<div className="mx-auto max-w-4xl text-center mt-16">
						<Badge size="lg" className="gap-2 bg-green-500/30 font-bold uppercase text-green-500">
							<WandSparkles /> Features
						</Badge>
						<div className="mx-auto max-w-2xl flex flex-col gap-2">
							<div className="mt-6 text-5xl text-gray-900 font-semibold">Undetectable Content. Every Time.</div>
							<p className="mt-6 text-xl leading-relaxed text-gray-700">
								While AI generation speeds up the writing process, it also opens yourself to risk of AI detection. Use
								our detector to check for AI-written writing and ensure your content is completely undetectable.
							</p>
						</div>
					</div>
					<div className="mx-auto max-w-6xl grid md:grid-cols-2 gap-4">
						{[
							{
								icon: <LibraryBig size={20} />,
								title: 'Homework',
								description:
									'Ace your assignments effortlessly. Our AI tool can help you finish your homework with original, teacher-approved content.',
							},
							{
								icon: <FileCheck2 size={20} />,
								title: 'Essays',
								description:
									'Need essay help? Use Clarify to generate an essay or research paper in seconds, complete with in-text citations from real articles, journals, and websites.',
							},
							{
								icon: <Newspaper size={20} />,
								title: 'Blogs',
								description: 'Use our AI tool to craft human-sounding content that engages readers and ranks well.',
							},
							{
								icon: <BriefcaseBusiness size={20} />,
								title: 'Work',
								description:
									'Accelerate your writing speed and boost your productivity—Clarify generates professional, undetectable content across a wide range of industries.',
							},
						].map((feature, index) => (
							<div key={index} className="flex flex-col gap-3 px-3 py-6 rounded-xl border-gray-300">
								<div className="text-lg flex items-center gap-2 font-semibold">
									<div className="p-2 bg-secondary rounded-full text-white">{feature.icon}</div> {feature.title}
								</div>
								<div className="text-gray-800">{feature.description}</div>
							</div>
						))}
					</div>
					<div className="max-w-3xl flex flex-col gap-2 items-start mt-12">
						<div className="mt-6 text-5xl text-gray-900 font-semibold">
							Bypass TurnItIn and other leading AI Detectors with Clarify AI.
						</div>
						<p className="mt-6 text-xl leading-relaxed text-gray-700">
							Instantly detect any AI-authored text by using our free AI detector.
						</p>
					</div>
					<div className="mx-auto max-w-6xl grid md:grid-cols-2 gap-4">
						{[
							{
								icon: <LibraryBig size={20} />,
								title: 'Accurate Detection',
								description:
									'Detect AI-authored text instantly with our free tool and make changes to ensure your content passes AI detectors.',
							},
							{
								icon: <FileCheck2 size={20} />,
								title: 'Completely Free',
								description: 'Enjoy unrestricted access to our AI detector at no charge, with no subscription.',
							},
							{
								icon: <Newspaper size={20} />,
								title: 'Humanization',
								description:
									'Is your content being flagged as AI-written? Use our humanizer to ensure it bypasses all detection tools.',
							},
							{
								icon: <BriefcaseBusiness size={20} />,
								title: 'Lightning-Fast',
								description:
									'Run your detection in seconds, not minutes. Our AI content detector quickly identifies and flags any AI-written content.',
							},
						].map((feature, index) => (
							<div key={index} className="flex flex-col gap-3 px-3 py-6 rounded-xl border-gray-300">
								<div className="text-lg flex items-center gap-2 font-semibold">
									<div className="p-2 bg-secondary rounded-full text-white">{feature.icon}</div> {feature.title}
								</div>
								<div className="text-gray-800">{feature.description}</div>
							</div>
						))}
					</div>
					<FAQ variant="detector" />
					<div className="mt-12">
						<TryFree />
					</div>
				</div>
			</div>
			<Footer />
		</>
	)
}

export default DetectorLandingPage
