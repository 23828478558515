import { Pencil } from 'lucide-react'
import { Button } from './ui/button'
import useEnterApp from '../hooks/enterApp'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../contexts/Auth'
import LoginModal from '../Components/Modals/Login'
import { useState } from 'react'
import { useLocalStorage } from '../helpers/utility'

const GetStartedButton = ({
	redirectUrl,
	cta = 'Get Started',
	size = 'xl',
	justText = false,
}: {
	redirectUrl?: string
	cta?: string
	size?: 'xl' | 'default' | 'sm' | 'lg' | 'icon' | null | undefined
	justText?: boolean
}) => {
	const [loginModalOpen, setLoginModalOpen] = useState(false)
	const { currentUser } = useAuth() as any
	const history = useHistory()
	const { enterApp } = useEnterApp({ history })
	const [annualPlan] = useLocalStorage('annualPlan', true)

	const handleClick = () => {
		if (currentUser) {
			enterApp()
		} else {
			setLoginModalOpen(true)
		}
	}

	return (
		<>
			<LoginModal
				premiumPath={false}
				open={loginModalOpen}
				setModalOpen={(open: boolean) => {
					setLoginModalOpen(open)
				}}
				annualPlan={annualPlan}
				loginVariant="signup"
				redirectUrl={redirectUrl}
			/>
			<Button size={size} onClick={() => handleClick()}>
				{!justText && !currentUser && <Pencil className="mr-2" />}
				{currentUser ? 'Dashboard' : cta}
				{!justText && !currentUser && (
					<span className="ml-1 text-base text-white/60 transition-all group-hover:text-white">- it's free</span>
				)}
			</Button>
		</>
	)
}

export default GetStartedButton
