import './_Thanks.scss'
import { useEffect, useMemo, useState } from 'react'
import { usePricing } from '../../hooks/featureFlags'
import { useDispatch, useSelector } from 'react-redux'
import { usePostHog } from 'posthog-js/react'
import { zarazLog } from '../../helpers/analytics'
import ThanksModal from './FreeTrial'
import useTestPayment from '../../hooks/useTestPayment'
import TestPaymentModal from './TestPayment'
import { selectUser } from '../../redux/systemSlice'
import { useLocalStorage } from '../../helpers/utility'

function Thanks({ open }: { open?: boolean }) {
	const [purchaseLogged, setPurchaseLogged] = useState(false)
	const [thanksModalOpen, setThanksModalOpen] = useState(false)
	const { testPaymentModalOpen } = useTestPayment({
		onSuccess: () => {
			setThanksModalOpen(true)
		},
	})
	const [annualPlan] = useLocalStorage('annualPlan', true)
	const price = usePricing()
	const posthog = usePostHog()
	const user = useSelector(selectUser)
	const searchParamsString = document.location.search
	const dispatch = useDispatch()

	useEffect(() => {
		if (open && !thanksModalOpen) {
			setThanksModalOpen(true)
		}
	}, [open])

	const searchParams = useMemo(() => {
		let params = new URLSearchParams(searchParamsString)

		return {
			showModal: params.getAll('showModal')[0],
			annual: params.getAll('annual')[0],
			freeTrial: params.getAll('freeTrial')[0],
		}
	}, [searchParamsString])

	useEffect(() => {
		if (searchParams.showModal) {
			const newSearchParams = new URLSearchParams(searchParamsString)
			newSearchParams.delete('showModal')
			const newUrl = window.location.pathname + (newSearchParams.toString() ? '?' + newSearchParams.toString() : '')
			window.history.replaceState(null, '', newUrl)
			if (searchParams.freeTrial !== 'true') {
				setThanksModalOpen(true)
			}
		}
	}, [searchParams, dispatch, price])

	useEffect(() => {
		const convertedFromFreeTrial = !user?.isOnFreeTrial && user?.testPaymentIntentStatus === 'SUCCEEDED'
		const convertedImmediately = !user?.isOnFreeTrial && !!user?.planId
		if (!purchaseLogged && thanksModalOpen && (convertedFromFreeTrial || convertedImmediately)) {
			setPurchaseLogged(true)
			zarazLog('purchase', {
				value: parseFloat(annualPlan ? price.yearlyTotal : price.monthly),
				currency: 'USD',
			})
			posthog?.capture('purchase', {
				price: annualPlan ? price.yearlyTotal : price.monthly,
				plan: annualPlan ? 'annual' : 'monthly',
			})
		}
	}, [thanksModalOpen, user, price, annualPlan, posthog, purchaseLogged])

	if (!user.uid) {
		return null
	}

	if (testPaymentModalOpen) {
		return <TestPaymentModal />
	}

	return <ThanksModal open={thanksModalOpen} setModalOpen={setThanksModalOpen} />
}

export default Thanks
