import { Link, useHistory } from 'react-router-dom'
import useEnterApp from '../hooks/enterApp'
import Logo from './Logo'
import { useIsAuthenticated } from '../hooks/authenticated'
import EmailImg from '../assets/images/email.png'

const products = [
	{ name: 'Content Generator', link: '/products' },
	{ name: 'AI Humanizer', link: '/humanizer' },
	{ name: 'AI Detector', link: '/detector' },
]

const legal = [
	{ name: 'Privacy Policy', link: '/privacy' },
	{ name: 'Terms of Service', link: '/terms' },
]

const Footer = () => {
	const isAuthenticated = useIsAuthenticated()
	const history = useHistory()
	const { enterApp } = useEnterApp({ history })

	return (
		<footer className="bg-secondary relative top-5 px-2 pt-16 flex flex-col justify-center items-center">
			<div className="max-w-6xl w-full grid sm:grid-cols-3 grid-cols-1 items-start gap-10 px-3 pb-24">
				<div className="flex flex-col gap-3">
					<Logo light />
					<div className="text-gray-200">Transform Your Writing with AI Magic</div>
				</div>
				<div className="flex flex-col gap-3">
					<div className="font-semibold text-white text-lg">Products</div>
					{products.map((product) => (
						<>
							{isAuthenticated ? (
								<Link to={product.link} className="text-white">
									{product.name}
								</Link>
							) : (
								<div className="text-white" onClick={() => enterApp()}>
									{product.name}
								</div>
							)}
						</>
					))}
				</div>
				<div className="flex flex-col gap-3">
					<div className="font-semibold text-white text-lg">Legal</div>
					{legal.map((product) => (
						<Link to={product.link} className="text-white">
							{product.name}
						</Link>
					))}
				</div>
			</div>
			<div className="text-center py-8 border-t border-primary-400 max-w-6xl w-full flex justify-between flex-wrap gap-2">
				<div className="text-primary-300">
					Copyright © {new Date().getFullYear()} Clarify AI, LLC. All rights reserved.
				</div>
				<div className="flex gap-2 items-center flex-wrap justify-center">
					<div className="text-primary-300">Have a question? Contact us at</div>
					<img src={EmailImg} className="h-5 object-cover mt-0.5" />
				</div>
			</div>
		</footer>
	)
}

export default Footer
