import React, { useState } from 'react'
import { Menu } from 'lucide-react'
import { Button } from './ui/button'
import { Link, useHistory } from 'react-router-dom'
import useEnterApp from '../hooks/enterApp'
import LoginModal from '../Components/Modals/Login'
import { useLocalStorage } from '../helpers/utility'
import { useAuth } from '../contexts/Auth'
import GetStartedButton from './get-started'
import Logo from '../Components/Logo'

const PageHeader: React.FC = () => {
	const [annualPlan] = useLocalStorage('annualPlan', true)
	const [loginModalOpen, setLoginModalOpen] = useState(false)
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const history = useHistory()
	const { enterApp } = useEnterApp({ history })
	const { currentUser } = useAuth() as any
	const pathname = window.location.pathname

	const launchButton = currentUser ? (
		<Button
			onClick={() => {
				enterApp()
			}}
			className="rounded-xl"
		>
			Launch App
		</Button>
	) : (
		<>
			<Button
				variant="ghost"
				onClick={() => {
					setLoginModalOpen(true)
				}}
			>
				Log in
			</Button>
			<GetStartedButton redirectUrl="/choose" cta="Start Writing" justText size="default" />
		</>
	)

	return (
		<>
			<LoginModal open={loginModalOpen} setModalOpen={setLoginModalOpen} annualPlan={annualPlan} />
			<header className="fixed top-0 z-20 w-full bg-white shadow-md dark:bg-[#162032]">
				<nav className="mx-auto flex w-full max-w-screen-xl flex-row flex-nowrap items-center justify-between p-4">
					<div className="flex flex-1">
						<Link to="/" className="flex flex-row items-center gap-1 text-secondary">
							<Logo />
						</Link>
					</div>
					<div className="hidden flex-row items-center gap-8 lg:flex">
						<Link to="/ai-humanizer" className={pathname.includes('ai-humanizer') ? 'font-semibold ' : ''}>
							AI Humanizer
						</Link>
						<Link to="/ai-detector" className={pathname.includes('ai-detector') ? 'font-semibold' : ''}>
							AI Detector
						</Link>
						<Link to="/products" className={pathname.includes('products') ? 'font-semibold' : ''}>
							AI Writer
						</Link>
						<Link to="/pricing" className={pathname.includes('pricing') ? 'font-semibold ' : ''}>
							Pricing
						</Link>
					</div>
					<div className="hidden flex-1 flex-row items-center justify-end gap-3 lg:flex">{launchButton}</div>
					<div
						className="lg:hidden"
						onClick={() => {
							setDropdownOpen(!dropdownOpen)
						}}
					>
						<Button variant="ghost" size="icon">
							<Menu />
						</Button>
					</div>
				</nav>
				<div className={`overflow-hidden lg:hidden bg-white ${dropdownOpen ? 'h-auto' : 'h-0'}`}>
					<div className="flex flex-col items-center gap-4 pb-3">
						<Link
							to="/ai-humanizer"
							className="transition-all hover:text-gray-900 block text-center"
							onClick={() => {
								setDropdownOpen(false)
							}}
						>
							AI Humanizer
						</Link>
						<Link
							to="/ai-detector"
							className="transition-all hover:text-gray-900 block text-center"
							onClick={() => {
								setDropdownOpen(false)
							}}
						>
							AI Detector
						</Link>
						<Link
							to="/products"
							className="transition-all hover:text-gray-900 block text-center"
							onClick={() => {
								setDropdownOpen(false)
							}}
						>
							AI Writer
						</Link>
						<Link
							to="/pricing"
							className="transition-all hover:text-gray-900 block text-center"
							onClick={() => {
								setDropdownOpen(false)
							}}
						>
							Pricing
						</Link>
						{launchButton}
					</div>
				</div>
			</header>
		</>
	)
}

export default PageHeader
