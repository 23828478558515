import { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { editorAtom, HumanizerIntensityLevel } from './constants'
import { RadioGroup, RadioGroupItem } from '../../components-landing/ui/radio-group'
import { Label } from '../../components-landing/ui/label'
import { Button as LandingButton } from '../../components-landing/ui/button'
import useIsPremium from '../../hooks/useIsPremium'
import StrengthExplainerModal from './strength-explainer'
import { useAuth } from '../../contexts/Auth'
import useHumanizerCredits from '../../hooks/useHumanizerCredits'
import { useSelector } from 'react-redux'
import { selectUser } from '../../redux/systemSlice'

const EditorTopSection = () => {
	const [editorState, setEditorState] = useAtom(editorAtom)
	const [copied, setCopied] = useState(false)
	const { creditsAvailable } = useHumanizerCredits()
	const isPremium = useIsPremium()
	const { currentUser } = useAuth() as any
	const user = useSelector(selectUser)

	useEffect(() => {
		if (copied) {
			const timeoutId = setTimeout(() => {
				setCopied(false)
			}, 2000)
			return () => clearTimeout(timeoutId)
		}
	}, [copied])

	return (
		<div className="grid md:grid-cols-3 gap-2 border-b px-4 py-3">
			<div className="flex flex-row items-center gap-2 md:justify-start justify-center">
				<div
					className={`h-3 w-3 rounded-full ${editorState.isLoadingHumanizeText ? 'bg-yellow-500' : 'bg-green-500'}`}
				></div>
				<div className="font-semibold">
					{editorState.isLoadingHumanizeText ? 'Humanizing' : 'Waiting for your input...'}
				</div>
			</div>
			<div className="flex md:flex-row flex-col gap-2 items-center w-full md:w-auto justify-center">
				<StrengthExplainerModal />
				<RadioGroup
					defaultValue="option1"
					className="flex md:flex-row flex-col items-center gap-2  w-full md:w-auto"
					onValueChange={(value: HumanizerIntensityLevel) =>
						setEditorState((editorState) => ({ ...editorState, humanizerIntensityLevel: value }))
					}
					value={editorState.humanizerIntensityLevel}
				>
					<Label htmlFor="option1" className="relative w-full md:w-auto">
						<RadioGroupItem
							id="option1"
							value={HumanizerIntensityLevel.LOW}
							className="peer absolute h-full w-full rounded-none opacity-0"
						/>
						<LandingButton
							variant="outline"
							className="peer-aria-checked:bg-gray-900 peer-aria-checked:text-white w-full md:w-auto"
						>
							Light
						</LandingButton>
					</Label>
					<Label htmlFor="option2" className="relative w-full md:w-auto">
						<RadioGroupItem
							id="option2"
							value={HumanizerIntensityLevel.MEDIUM}
							className="peer absolute h-full w-full rounded-none opacity-0"
						/>
						<LandingButton
							variant="outline"
							className="peer-aria-checked:bg-gray-900 peer-aria-checked:text-white w-full md:w-auto"
						>
							Medium
						</LandingButton>
					</Label>
					<Label htmlFor="option3" className="relative w-full md:w-auto">
						<RadioGroupItem
							id="option3"
							value={HumanizerIntensityLevel.HIGH}
							className="peer absolute h-full w-full rounded-none opacity-0"
						/>
						<LandingButton
							variant="outline"
							className="peer-aria-checked:bg-gray-900 peer-aria-checked:text-white w-full md:w-auto"
						>
							Strong
						</LandingButton>
					</Label>
				</RadioGroup>
			</div>
			<div
				className={`text-gray-700 md:text-right text-center pt-2 ${
					(isPremium && !user.isOnFreeTrial) || !currentUser ? 'invisible' : 'visible'
				}`}
			>
				{creditsAvailable} words remaining
			</div>
		</div>
	)
}

export default EditorTopSection
