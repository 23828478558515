import { useSelector } from 'react-redux'
import { useAuth } from '../contexts/Auth'
import { selectUser } from '../redux/systemSlice'
import { usePricing } from './featureFlags'
import { useState } from 'react'

const useCheckout = () => {
	const [loading, setLoading] = useState(false)
	const { currentUser } = useAuth() as any
	const user = useSelector(selectUser)
	const pricing = usePricing()

	const checkout = async (annual: boolean, freeTrial?: boolean) => {
		if (!user) return
		setLoading(true)
		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				authorization: currentUser ? `Bearer ${await currentUser.getIdToken()}` : '',
			},
			body: JSON.stringify({
				uid: currentUser.uid,
				humanizerUpgrade: false,
				price: annual ? pricing.stripePriceId.yearly ?? pricing.stripePriceId.monthly : pricing.stripePriceId.monthly,
				annual: annual,
				returnUrl: window.location.href,
				freeTrial: freeTrial,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/payments/checkout/', requestOptions)
			.then((res) => res.json())
			.then((response: { url: string }) => {
				setLoading(false)
				if (response.url) {
					window.open(response.url, '_self')
				}
			})
			.catch((err) => {
				console.error(err)
			})
	}

	return { checkout, loading }
}

export default useCheckout
