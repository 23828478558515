import { CircleHelpIcon } from 'lucide-react'
import { Badge } from './ui/badge'
import { primaryFaqs, detectorFaqs, humanizerFaqs } from '../lib/faqs'

const FAQ = ({ variant = 'humanizer' }: { variant?: 'detector' | 'humanizer' | 'primary' }) => {
	const variantFaq = {
		primary: primaryFaqs,
		humanizer: humanizerFaqs,
		detector: detectorFaqs,
	}[variant]
	const desc = {
		primary: 'Find answers to commonly asked questions about Clarify.',
		humanizer: 'Questions about our AI Humanizer? Find answers here.',
		detector: 'Find answers to commonly asked questions about the AI Detector.',
	}[variant]
	return (
		<div className="mt-24 md:mt-30">
			<div className="mx-auto max-w-4xl text-center">
				<Badge
					size="lg"
					className="gap-2 font-bold uppercase bg-gradient-to-r from-purple-200 to-purple-400 text-purple-800"
				>
					<CircleHelpIcon /> Support
				</Badge>
				<h2 className="mt-6 text-4xl sm:text-6xl/tight text-gray-900">Frequently Asked Questions</h2>
				<p className="mt-6 text-xl">{desc}</p>
			</div>
			<div className="mt-10 flex flex-col gap-6">
				{variantFaq.map((faq, index) => (
					<div key={index} className="rounded-xl border bg-card p-4 shadow-md">
						<h4 className="text-lg font-normal !text-secondary">{faq.question}</h4>
						<div className="text-gray-700">{faq.answer}</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default FAQ
