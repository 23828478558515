import { AlertTriangle, CheckCircle2, Flag, Info, InfoIcon, Loader, LoaderCircle, XCircle } from 'lucide-react'
import { Button } from './ui/button'
import Modal from './ui/modal'
import { useEffect, useMemo, useState } from 'react'
import { useAtom } from 'jotai'
import { DetectionResponse, editorAtom, MAX_DETECTOR_WORD_COUNT } from '../Pages/Humanizer/constants'
import { useWordCount } from '../Pages/Humanizer/hooks'
import useLoadingDots from '../hooks/useLoadingDots'
import { Tooltip } from '@mui/material'
import { usePostHog } from 'posthog-js/react'

const detectors = [
	{ name: 'turnitin', displayName: 'Turnitin', score: 0 },
	{ name: 'gptzero', displayName: 'GPTZero', score: 0 },
	{ name: 'copyleaks', displayName: 'Copyleaks', score: 0 },
	{ name: 'sapling', displayName: 'Sapling', score: 0 },
	{ name: 'contentatscale', displayName: 'ContentAtScale', score: 0 },
	{ name: 'zerogpt', displayName: 'ZeroGPT', score: 0 },
]

const CheckForAi = ({
	text,
	label = 'Check for AI',
	emphasizeBtn = false,
	humanized = false,
	onHumanizeClick,
}: {
	text: string
	label?: string
	emphasizeBtn?: boolean
	humanized?: boolean
	onHumanizeClick: () => void
}) => {
	const [detectionModalOpen, setDetectionModalOpen] = useState(false)
	const [thirdPartyScoresLoading, setThirdPartyScoresLoading] = useState(false)
	const [editorState, setEditorState] = useAtom(editorAtom)
	const wordCount = useWordCount(text)
	const loadingDots = useLoadingDots(editorState.aiDetectionScoreLoading)
	const posthog = usePostHog()

	const NoAI = <CheckCircle2 size={18} className="text-green-600" />
	const LikelyAI = <AlertTriangle size={18} className="text-yellow-600" />
	const AI = <XCircle size={18} className="text-red-500" />
	const _humanized = humanized || editorState.textInputValue.trim() === editorState.humanizedText?.trim()

	const checkForAI = async () => {
		posthog.capture('detect', {
			num_words: wordCount,
		})

		setEditorState((editorState) => ({ ...editorState, aiDetectionScoreLoading: true }))

		const requestOptions = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				text: text,
				individualScores: true,
			}),
		}
		fetch(process['env']['REACT_APP_API_ROOT'] + '/essay/secondary-client-detect/', requestOptions)
			.then((res) => res.json())
			.then((response: DetectionResponse) => {
				setEditorState((editorState) => ({ ...editorState, aiDetectionScoreLoading: false }))
				setEditorState({
					...editorState,
					aiDetectionScore: response,
					aiDetectionScoreText: text,
					showDetectionBox: true,
				})
			})
	}

	const scores = useMemo(() => {
		if (_humanized) {
			return detectors
		}
		return detectors.map((detector) => {
			if (!editorState.aiDetectionScore) {
				return {
					name: detector.name,
					displayName: detector.displayName,
					score: 0,
				}
			}
			if (detector.name in editorState.aiDetectionScore) {
				return {
					name: detector.name,
					displayName: detector.displayName,
					// @ts-ignore
					score: editorState.aiDetectionScore[detector.name],
				}
			}
			return {
				name: detector.name,
				displayName: detector.displayName,
				score: 0,
			}
		})
	}, [editorState.aiDetectionScore, _humanized])

	const isAI = useMemo(() => {
		if (_humanized) {
			return false
		}
		const averageScore = scores.reduce((acc, detector) => acc + detector.score, 0) / scores.length
		return averageScore >= 50
	}, [editorState.aiDetectionScore, _humanized])

	useEffect(() => {
		if (detectionModalOpen && !_humanized) {
			checkForAI()
		}
		if (_humanized && detectionModalOpen) {
			setEditorState((editorState) => ({ ...editorState, aiDetectionScoreLoading: true }))
			setThirdPartyScoresLoading(true)
			const timer = setTimeout(
				() => setEditorState((editorState) => ({ ...editorState, aiDetectionScoreLoading: false })),
				2000
			)
			const secondTimer = setTimeout(() => setThirdPartyScoresLoading(false), 4000)
			return () => {
				clearTimeout(timer)
				clearTimeout(secondTimer)
			}
		}
	}, [detectionModalOpen, _humanized])

	const checkForAIBtn = (
		<Button
			variant={emphasizeBtn ? 'shadow' : 'outline'}
			onClick={() => setDetectionModalOpen(true)}
			disabled={
				wordCount === 0 ||
				editorState.isLoadingHumanizeText ||
				wordCount > MAX_DETECTOR_WORD_COUNT ||
				(editorState.aiDetectionScoreLoading && detectionModalOpen)
			}
			className={emphasizeBtn ? 'text-white' : ''}
		>
			<Flag className="mr-2" /> {label}
		</Button>
	)

	if (editorState.aiDetectionScoreLoading) {
		return (
			<>
				{checkForAIBtn}
				<Modal open={detectionModalOpen} setOpen={setDetectionModalOpen} className="max-w-3xl">
					<div className="flex items-center justify-center h-72 text-2xl font-semibold text-secondary">
						Checking for AI{loadingDots}
					</div>
				</Modal>
			</>
		)
	}

	return (
		<>
			<Modal open={detectionModalOpen} setOpen={setDetectionModalOpen} className="max-w-3xl overflow-y-auto">
				<div className="flex flex-col gap-4">
					<div className="flex flex-wrap items-center gap-3 justify-between pr-5">
						<div className="flex flex-wrap items-center gap-3">
							<div
								className={`${
									isAI ? 'border-red-500' : 'border-green-500 text-xs'
								} flex h-14 w-14 items-center justify-center rounded-full border-4 border-green-500 bg-card font-semibold`}
							>
								{isAI ? 'AI' : 'Human'}
							</div>
							<div className="font-semibold text-lg">
								Your text is likely to be written{' '}
								{isAI ? (
									<>
										by an <span className="text-red-600">AI</span>
									</>
								) : (
									<>
										by a <span className="text-green-600">human</span>
									</>
								)}
								.
							</div>
						</div>
						{isAI && (
							<Button
								variant="shadow"
								className="text-white"
								onClick={() => {
									setDetectionModalOpen(false)
									onHumanizeClick()
								}}
							>
								Humanize
							</Button>
						)}
					</div>
					<p className="text-center text-sm text-gray-700">
						<Info className="inline" size={16} /> Given the rapid evolution of AI-generated content, these results
						should not be used to penalize students.
					</p>
					<div className="rounded border p-4 dark:text-slate-200 max-h-72 overflow-y-auto">{text}</div>
					<div className="flex flex-wrap justify-between">
						<Tooltip
							title="Clarify utilizes AI technology to assess the likelihood that major AI detectors will identify a piece of text as AI-generated. The models are trained on text similar to that used by leading detection platforms, ensuring our predictions align closely with their outcomes."
							placement="top-start"
						>
							<div>
								<div className="text-lg font-semibold dark:text-slate-200">
									Third-Party AI Scores <InfoIcon className="inline-block ml-1" />
								</div>
							</div>
						</Tooltip>
						<div className="flex gap-4">
							<div className="flex items-center gap-1 font-semibold text-green-600">
								{NoAI}
								Human
							</div>
							<div className="flex items-center gap-1 font-semibold text-yellow-600">
								{LikelyAI}
								Likely AI
							</div>
							<div className="flex items-center gap-1 font-semibold text-red-500">
								{AI}
								AI
							</div>
						</div>
					</div>
					<div className="md:flex grid grid-cols-2 justify-between gap-2 overflow-x-scroll rounded border bg-card p-3">
						{scores.map((detector) => {
							let icon = null
							if (thirdPartyScoresLoading) {
								icon = <LoaderCircle className="h-5 w-5 animate-spin duration-500" />
							} else if (detector.score > 50) {
								icon = AI
							} else if (detector.score <= 50 && detector.score > 35) {
								icon = LikelyAI
							} else if (detector.score <= 35) {
								icon = NoAI
							}
							return (
								<>
									<div className="flex items-center gap-2">
										{icon}
										<div className="max-w-[5rem] md:max-w-none truncate md:overflow-visible md:whitespace-normal">
											{detector.displayName}
										</div>
									</div>
								</>
							)
						})}
					</div>
				</div>
			</Modal>
			{checkForAIBtn}
		</>
	)
}

export default CheckForAi
